import React from 'react';
import styled from 'styled-components';
import { colors } from '../../theme/constants';
import { device } from '../../theme';

const FocusBoxContainer = styled.div`
  width: 100%;
  position: relative;
  min-height: 1px;
  display: flex;
  padding: 1rem 0;

  @media ${device.laptop} {
    width: ${(props) => (props.fullWidth ? '100%' : '49%')};
    padding: 0;
  }
`;

const BgWrapper = styled.div`
  background-image: url(${(props) => props.bgUrl});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  position: relative;
  display: flex;
  :before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: '';
    background: rgba(0, 0, 0, 0.5);
  }
`;

const ContentBox = styled.div`
  position: relative;
  width: 100%;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 4rem;
`;

const ContentHeader = styled.h5`
  line-height: 3rem;
  color: ${(props) =>
    props.headerColor ? props.headerColor : colors.grayf5f5f5};
  margin-bottom: 1rem;
  transition: all 0.3s linear;
  font-size: 2.5rem;
  font-weight: 800;
`;

const ContentDetalis = styled.p`
  color: ${(props) => (props.detailsColor ? props.detailsColor : '#fff')};
  font-size: 1.5rem;
`;

const FocusBox = (props) => {
  return (
    <>
      <FocusBoxContainer fullWidth={props.fullWidth}>
        <BgWrapper bgUrl={props.bgUrl}>
          <ContentBox border={props.border}>
            {/* <ContentTopic topicColor={props.topicColor}>
              {props.topic}
            </ContentTopic> */}
            <ContentHeader headerColor={props.headerColor}>
              {props.header}
            </ContentHeader>
            <ContentDetalis detailsColor={props.detailsColor}>
              {props.details}
            </ContentDetalis>
          </ContentBox>
        </BgWrapper>
      </FocusBoxContainer>
    </>
  );
};

export default FocusBox;
