import React from 'react';
import styled from 'styled-components';
import { colors } from '../../theme/constants';
import { device } from '../../theme';

const SquareContainer = styled.div`
  right: 3rem;
  top: 0;
  transform: rotate(45deg);
  position: absolute;
  @media ${device.desktop} {
    right: 0;
  }
`;

const SquareShape = styled.div`
  width: 6rem;
  height: 6rem;
  border: 0.4rem solid ${colors.yellow.orangeYellow};
  opacity: 0.6;
  @media ${device.laptop} {
    border: 1rem solid ${colors.yellow.orangeYellow};
    width: 15rem;
    height: 15rem;
  }
`;

const SmallContainer = styled.div`
  right: 3rem;
  top: 0;
  transform: rotate(45deg);
  position: absolute;
  @media ${device.desktop} {
    right: 0;
  }
`;
const SmallSquare = styled.div`
  width: 2rem;
  height: 2rem;
  border: 0.3rem solid ${colors.yellow.mainDarkYellow};
  opacity: 0.7;
  @media ${device.laptop} {
    border: 0.5rem solid ${colors.yellow.mainDarkYellow};
    width: 5rem;
    height: 5rem;
  }
`;

const SquareDesign = (props) => {
  return (
    <>
      <SquareContainer>
        <SquareShape></SquareShape>
      </SquareContainer>
      <SmallContainer>
        <SmallSquare></SmallSquare>
      </SmallContainer>
    </>
  );
};

export default SquareDesign;
