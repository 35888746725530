import React from 'react';
import { Container } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import MainContentWrapper from '../common/MainContentWrapper';
import { colors } from '../../theme/constants';
import { device } from '../../theme';

const MainSection = styled.section`
  will-change: transform;
  z-index: 1;
  position: relative;
  text-align: center;
  margin: 0 auto;
  padding: 1.9rem 0 22.5rem;
  width: 100%;
  @media ${device.mobileL} {
    padding: 3.9rem 0 3rem;
  }
  @media ${device.tablet} {
    padding: 5rem 0 3.5rem;
  }
  @media ${device.laptop} {
    max-width: 1040px;
    padding: 10rem 0 29rem;
  }
  @media ${device.laptopM} {
    max-width: 1040px;
    padding: 8rem 0 32rem;
  }
`;

const CommonGrid = styled.div`
  --boxes-height: 4.8rem;
  --content-columns: 12;
  --gutter-columns: 4;
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  z-index: -1;
  pointer-events: none;
  @media ${device.laptopM} {
    --boxes-height: 6.4rem;
  }
`;

const BackgroundContainer = styled.div`
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  transform: skewY(-12deg);
`;

const Gridgrid = styled.div`
  height: 200%;
  align-content: end;
  grid-template-columns: 1fr;
  min-width: 0;
  --content-column-width: minmax(0, calc(1040px / var(--content-columns)));
  --content-column-width: minmax(0, calc(1040px / var(--content-columns)));
  position: absolute;
  width: 100%;
  display: grid;
  grid-template-rows: repeat(auto-fill, var(--boxes-height));
  --gutter-column-width: var(--content-column-width);
  --content-column-width: minmax(0, calc(1040px / var(--content-columns)));
`;

const GridSecond = styled.div`
  height: 200%;
  align-content: end;
  min-width: 0;
  --content-column-width: minmax(0, calc(1040px / var(--content-columns)));
  --content-column-width: minmax(0, calc(1040px / var(--content-columns)));
  position: absolute;
  width: 100%;
  display: grid;
  grid-template-rows: repeat(auto-fill, var(--boxes-height));
  grid-template-columns:
    [viewport-start] 1fr [left-gutter-start] repeat(
      var(--gutter-columns),
      var(--gutter-column-width)
    )
    [left-gutter-end content-start] repeat(
      var(--content-columns),
      var(--content-column-width)
    )
    [content-end right-gutter-start] repeat(
      var(--gutter-columns),
      var(--gutter-column-width)
    )
    [right-gutter-end] 1fr [viewport-end];
  --gutter-column-width: 1fr;
  --content-column-width: minmax(0, calc(1040px / var(--content-columns)));
`;

const BgMainGray = styled.div`
  grid-row: 1/-4;
  grid-column: 1/-1;
  z-index: -1;
  @media ${device.laptop} {
    background-color: ${colors.gray.mainLightGray};
  }
`;

const DesignedContainer = styled.div`
  justify-content: flex-end;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  transform: skewY(-12deg);
`;

const BoxOne = styled.div`
  grid-row: -13;
  /* grid-row: -11; */
  grid-column: span 3;
  /* grid-column: span 4/6; */
  /* box-shadow: inset 0 0 0 1.5px ${colors.gray.mainGrey}; */
  background-color: ${colors.blue.mainBlue};
`;
const BoxTwo = styled.div`
  grid-row: -12;
  /* grid-row: -10; */
  grid-column: span 2;
  /* grid-column: span 4/4; */
  background-color: ${colors.yellow.mainLightYellow};
`;
const BoxThree = styled.div`
  grid-row: -6;
  /* grid-column: span 3/8; */
  grid-column: span 5/8;
  /* box-shadow: inset 0 0 0 1.5px ${colors.gray.mainGrey}; */
  background-color: ${colors.gray.mainGrey};
`;
const BoxFour = styled.div`
  grid-row: -6;
  /* grid-column: viewport-start/span 9; */
  grid-column: viewport-start/span 2;
  background-color: ${colors.blue.mainBlue};
`;
const BoxFive = styled.div`
  grid-row: -5;
  grid-column: span 7/9;
  background-color: ${colors.yellow.mainLightYellow};
`;
const BoxSix = styled.div`
  grid-row: -5;
  grid-column: span 5/7;
  background-color: ${colors.yellow.mainDarkYellow};
`;
const BoxSeven = styled.div`
  grid-row: -12;
  /* grid-row: -10; */
  grid-column: span 3 / viewport-end;
  /* grid-column: span 4 / viewport-end; */
  background-color: ${colors.blue.mainBlue};
`;
const BoxEight = styled.div`
  grid-row: -11;
  /* grid-row: -9; */
  grid-column: span 4 / viewport-end;
  /* grid-column: span 5 / viewport-end; */
  background-color: ${colors.yellow.mainDarkYellow};
`;

const BoxTest = styled.div`
  grid-row: -8;
  /* grid-column: span 7 / viewport-end; */
  grid-column: span 6 / viewport-end;
  box-shadow: inset 0 0 0 1.5px ${colors.gray.mainGrey};
`;

const BoxNine = styled.div`
  grid-row: -4;
  /* grid-column: span 7 / viewport-end; */
  grid-column: span 6 / viewport-end;
  background-color: ${colors.blue.mainBlue};
`;
const BoxTen = styled.div`
  grid-row: -3;
  /* grid-column: span 3/-6; */
  grid-column: span 4/-6;
  box-shadow: inset 0 0 0 1.5px ${colors.gray.mainGrey};
`;
const BoxEleven = styled.div`
  grid-row: -2;
  grid-column: span 5 / viewport-end;
  background-color: ${colors.yellow.mainLightYellow};
`;

const ScrollDown = styled.div`
  position: absolute;
  bottom: 1rem;
  left: 0;
  transform: translateX(50vw);
  transform-origin: center center;
  color: ${colors.blue.mainBlue};
  @-webkit-keyframes scrollDownMove {
    0% {
      transform: translateY(-0.15em);
    }
    to {
      transform: translateY(0.65em);
    }
  }
  @keyframes scrollDownMove {
    0% {
      transform: translateY(-0.15em);
    }
    to {
      transform: translateY(0.65em);
    }
  }
  svg {
    width: 2.5rem;
    height: 2.5rem;
  }
`;

const SlideGrid = (props) => {
  return (
    <div style={{ position: 'relative' }}>
      <MainSection>
        <Container>
          <MainContentWrapper
            fontColor={colors.blue.mainBlue}
            colorFont={colors.yellow.mainDarkYellow}
            pageLabel={'Got an idea?'}
            title={'Need a website, web or mobile app?'}
            contentDetails={
              'Teams of experienced developers and designers with a singular focus, ready to help you build your web and mobile applications. We deliver your vision on time, on spec and on budget.'
            }
          />
        </Container>
      </MainSection>
      <ScrollDown>
        <svg viewBox="0 0 25.167 37.87">
          <path
            fill="none"
            stroke="currentcolor"
            strokeWidth="2.5"
            strokeMiterlimit="10"
            d="M12.583 36.62h0c-6.233.0-11.333-5.1-11.333-11.333V12.583C1.25 6.35 6.35 1.25 12.583 1.25h0c6.234.0 11.334 5.1 11.334 11.333v12.704c0 6.233-5.1 11.333-11.334 11.333z"
          ></path>
          <path
            style={{
              animation: 'scrollDownMove .8s ease-in-out alternate infinite',
            }}
            fill="currentcolor"
            d="M13.083 19.216h-.919c-1.1.0-2-.9-2-2v-5.429c0-1.1.9-2 2-2h.92c1.1.0 2 .9 2 2v5.429c0 1.1-.9 2-2 2z"
          ></path>
        </svg>
      </ScrollDown>
      <CommonGrid>
        <BackgroundContainer>
          <Gridgrid>
            <BgMainGray></BgMainGray>
          </Gridgrid>
        </BackgroundContainer>
        <DesignedContainer>
          <GridSecond>
            <BoxOne />
            <BoxTwo />
            <BoxThree />
            <BoxFour />
            <BoxFive />
            <BoxSix />
            <BoxSeven />
            <BoxEight />
            <BoxTest />
            <BoxNine />
            <BoxTen />
            <BoxEleven />
          </GridSecond>
        </DesignedContainer>
      </CommonGrid>
    </div>
  );
};

export default SlideGrid;
