import React from 'react';
import { Container } from 'styled-bootstrap-grid';
import { SectionWrapper } from '../common/SectionWrapper';
import { Grid, Cell } from '../../theme';
import styled from 'styled-components';
import ContentWrapper from '../common/ContentWrapper';
import { colors } from '../../theme/constants';
import { FetchSvg } from '../common/FetchSvg';

const SectionContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
`;

const ImgContainer = styled.div`
  text-align: center;
  position: relative;
  transform: rotateZ(10deg);
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  border-radius: 8px;

  svg {
    width: 100%;
  }
`;

const AboutCompanySection = (props) => {
  return (
    <>
      <SectionWrapper>
        <Container>
          <Grid columnGap={'6rem'} rows={1} columns={1}>
            <Cell>
              <ContentWrapper
                fontColor={colors.yellow.mainDarkYellow}
                colorFont={colors.blue.mainBlue}
                colorBlue={colors.blue.textBlue}
                pageLabel={'// about company'}
                center={true}
                title={'Your Partner for Software Development'}
                contentOne={
                  'We are a software development company that provides cutting edge engineering solutions for your unique business challenges. Using experience and consideration, we create a custom look and functionality for your software that helps push your business forward. We deliver the product you need, to get the results you want. You can trust in Softiware’s experience and knowledge of business applications and enterprise-grade software.'
                }
                contentTwo={''}
              />
            </Cell>
            {/* <Cell>
              <SectionContainer>
                <ImgContainer>
                  <FetchSvg name="static_website" />
                </ImgContainer>
              </SectionContainer>
            </Cell> */}
          </Grid>
        </Container>
      </SectionWrapper>
    </>
  );
};

export default AboutCompanySection;
