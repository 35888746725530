import React from 'react';
import styled from 'styled-components';
import { device } from '../../theme';

const TextsContainer = styled.div`
  padding: 2rem 0;
  text-align: ${(props) => (props.center ? 'center' : '')};
`;

const PageLabel = styled.p`
  font-size: 1.5rem;
  font-weight: 400;
  color: ${(props) => (props.fontColor ? props.fontColor : 'black')};
  position: relative;
  display: inline-block;
  margin-bottom: 2rem;
  text-transform: uppercase;
  @media ${device.tablet} {
    font-weight: 800;
  }
`;

const ContentTitle = styled.h2`
  font-size: 3rem;
  font-weight: 600;
  line-height: 1.33;
  color: ${(props) => (props.colorFont ? props.colorFont : '#1b1d21')};
  text-transform: none;
  padding-bottom: 3rem;
  @media ${device.laptop} {
    font-size: 3.6rem;
    font-weight: 800;
  }
`;

const ContentDetails = styled.p`
  margin-bottom: 2rem;
  font-size: 1.9rem;
  color: ${(props) => (props.colorBlue ? props.colorBlue : '#1b1d21')};
`;
const ContentWrapper = (props) => {
  return (
    <>
      <TextsContainer center={props.center}>
        <PageLabel fontColor={props.fontColor}>{props.pageLabel}</PageLabel>
        <ContentTitle colorFont={props.colorFont}>{props.title}</ContentTitle>
        <ContentDetails colorBlue={props.colorBlue}>
          {props.contentOne}
        </ContentDetails>
        <ContentDetails colorBlue={props.colorBlue}>
          {props.contentTwo}
        </ContentDetails>
      </TextsContainer>
    </>
  );
};

export default ContentWrapper;
