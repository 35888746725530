import React from 'react';
import Layout from '../components/Layout';
import AboutCompanySection from '../components/Home/AboutCompanySection';
import WhyChooseUsSection from '../components/Home/WhyChooseUsSection';
import OurServicesSection from '../components/Home/OurServicesSection';
import ProjectsSection from '../components/Home/ProjectsSection';
import SlideGrid from '../components/Home/SlideGrid';
// import MotionSlider from '../components/common/MotionSlider';
import ElementorSection from '../components/common/ElementorSection';
import { colors } from '../theme/constants';

const HomePage = () => {
  return (
    <Layout>
      {/* <HeroBanner /> */}
      <SlideGrid />
      {/* <SectionWrapper bgUrl={'/images/test-bg.png'} hideSection /> */}
      <AboutCompanySection />
      {/* <MotionSlider></MotionSlider> */}
      <WhyChooseUsSection />
      <OurServicesSection />
      <ProjectsSection />
      {/* <SectionWrapper hideSection /> */}
      <ElementorSection
        topic={"Let's build your web or mobile app!"}
        title={'NEED A CONSULTATION?'}
        textColor={colors.yellow.mainDarkYellow}
        titleColor={colors.blue.mainBlue}
      />
    </Layout>
  );
};

export default HomePage;
