import React from 'react';
import styled from 'styled-components';
import { colors } from '../../theme/constants';
import { FetchSvg } from '../common/FetchSvg';

const ContentHeader = styled.h1`
  /* line-height: 30px; */
  margin-bottom: 10px;
  /* transition: all 0.3s linear; */
  font-size: 3rem;
  font-weight: 800;
  /* padding-bottom: 3rem; */
  font-style: italic;
  background: linear-gradient(to right, #32325d 0%, #f5be58 100%);
  background-clip: text;
`;

const ContentDetalis = styled.div`
  padding: 0 1rem;
  font-size: 2rem;
  font-weight: 400;
`;
const ServiceBoxLayer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  /* background: rgba(12, 41, 53, 0.5); */
  /* background: rgba(229, 160, 62, 0.5); */
  background: rgba(187, 170, 146, 0.5);
  opacity: 0;
  transition: all 0.3s linear;
  z-index: 0;
`;

const ContentBox = styled.div`
  z-index: 1000;
  text-align: center;
  position: absolute;
  /* padding: 8rem 6rem 1rem 6rem; */
  top: 50%;
  transform: translateY(-50%);
  color: ${colors.white};
`;
const SvgIcon = styled.span`
  color: #a8bccc;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  font-weight: 900;
  svg {
    width: ${(props) => (props.bigIcon ? '20rem' : '15rem')};
    height: ${(props) => (props.bigIcon ? '20rem' : '15rem')};
  }
`;
const BgSvgIcon = (props) => {
  return (
    <SvgIcon bigIcon={props.bigIcon}>
      <FetchSvg name={props.iconName} />
    </SvgIcon>
  );
};

const ServiceBoxContent = styled.div`
  border: solid 1px #e7e7e7;
  overflow: hidden;
  position: relative;
  padding: 43px 30px 65px;
  color: #aeaacb;
  min-height: 1px;
  height: 50rem;
  transition: all 0.3s linear;
  background: url(${(props) => props.bgUrl});
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  opacity: 0.9;

  :hover {
    /* filter: grayscale(100%); */

    ${ContentDetalis} {
      color: white;
    }
    ${ContentHeader} {
      color: white;
    }
    ${ServiceBoxLayer} {
      opacity: 1;
    }
    ${SvgIcon} {
      opacity: 0.3;
    }
  }
`;

const ProjectBox = (props) => {
  return (
    <>
      <ServiceBoxContent bgUrl={props.bgUrl}>
        <BgSvgIcon iconName={props.iconName} bigIcon={props.bigIcon} />
        <ServiceBoxLayer>
          <ContentBox>
            <ContentDetalis>{props.details}</ContentDetalis>
          </ContentBox>
        </ServiceBoxLayer>
      </ServiceBoxContent>
    </>
  );
};

export default ProjectBox;
