import React from 'react';
import { Container } from 'styled-bootstrap-grid';
import { SectionWrapper } from './SectionWrapper';
import styled from 'styled-components';
import Buttons from './Buttons';
import { colors } from '../../theme/constants';
import { device } from '../../theme';

const ElementorContainer = styled.div`
  margin-right: auto;
  margin-left: auto;
  position: relative;
  text-align: center;
`;

const ElementorTopic = styled.p`
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.4em;
  padding-bottom: 1.3rem;
  color: ${(props) => (props.textColor ? props.textColor : 'black')};
  @media ${device.laptop} {
    font-size: 2.4rem;
    font-weight: 400;
    line-height: 1.4em;
  }
`;

const ElementorTitle = styled.h2`
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 2em;
  padding-bottom: 2rem;
  color: ${(props) => (props.titleColor ? props.titleColor : 'black')};
  @media ${device.tablet} {
    font-size: 3rem;
    font-weight: 800;
    line-height: 4rem;
  }
  @media ${device.laptop} {
    font-size: 3.5rem;
    font-weight: 800;
    line-height: 8rem;
  }
  @media ${device.laptopM} {
    font-size: 4rem;
    font-weight: 800;
    line-height: 8rem;
  }
`;

const ElementorSection = (props) => {
  return (
    <>
      <SectionWrapper bgUrl={''}>
        <Container>
          <ElementorContainer>
            <ElementorTopic textColor={props.textColor}>
              {props.topic}
            </ElementorTopic>
            <ElementorTitle titleColor={props.titleColor}>
              {props.title}
            </ElementorTitle>
            <Buttons
              center
              title={'CONTACT US'}
              backgoundcolor={colors.blue.pageHeaderBlue}
              textColor={colors.white}
              link={'/contact'}
            />
          </ElementorContainer>
        </Container>
      </SectionWrapper>
    </>
  );
};

export default ElementorSection;
