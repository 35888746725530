import React from 'react';
import styled from 'styled-components';
import { colors } from '../../theme/constants';
import { device } from '../../theme';
import { FetchSvg } from '../common/FetchSvg';

const ContentHeader = styled.h5`
  line-height: 3rem;
  color: ${colors.blue.mainBlue};
  margin-bottom: 2rem;
  transition: all 0.3s linear;
  font-size: 2rem;
  font-weight: 800;
  @media ${device.mobileL} {
    font-size: 1.6rem;
    margin-bottom: 1rem;
  }
  @media ${device.tablet} {
    font-size: 1.8rem;
    margin-bottom: 1rem;
    font-weight: 600;
  }

  @media ${device.laptopM} {
    font-size: 2.3rem;
    margin-bottom: 1rem;
  }
`;

const ContentDetalis = styled.div`
  color: ${colors.blue.textBlue};
  font-size: 1.6rem;

  @media ${device.tablet} {
    font-size: 1.8rem;
  }
`;

const IconContainer = styled.div`
  color: ${colors.blue.mainBlue};
  margin-bottom: 2rem;
`;

const ServiceBoxContent = styled.div`
  border: solid 1px #525f7f;
  overflow: hidden;
  position: relative;
  padding: 3rem 1rem;
  color: #aeaacb;
  min-height: 0.1rem;
  height: 30rem;
  transition: all 0.3s linear;
  :after {
    content: '';
    position: absolute;
    width: 2.3rem;
    height: 2.3rem;
    top: -1rem;
    right: -1rem;
    z-index: 1;
    opacity: 0;
    transition: all 0.3s linear;
    transform: rotate(45deg);
    background: ${colors.yellow.mainDarkYellow};
  }
  :hover {
    background: url(${(props) => props.bgUrl});
    background-repeat: no-repeat;
    background-size: cover;

    :after {
      opacity: 1;
    }

    ${IconContainer} {
      color: ${colors.yellow.mainDarkYellow};
    }
    ${ContentDetalis} {
      color: ${colors.white};
    }
    ${ContentHeader} {
      color: white;
    }
  }
`;

const ContentBox = styled.div`
  position: relative;
  z-index: 1;
  text-align: center;
`;

const IconShape = styled.div`
  text-align: center;
  svg,
  img {
    width: 3rem;
    height: 3rem;
  }

  @media ${device.laptopM} {
    svg,
    img {
      width: 4rem;
      height: 4rem;
    }
  }
`;

const BgSvgIcon = (props) => {
  return (
    <IconShape>
      <FetchSvg name={props.iconName} />
    </IconShape>
  );
};

const ServiceBox = (props) => {
  return (
    <>
      <ServiceBoxContent bgUrl={props.bgUrl}>
        <IconContainer>
          <BgSvgIcon iconName={props.iconName} />
        </IconContainer>
        <ContentBox>
          <ContentHeader>{props.header}</ContentHeader>
          <ContentDetalis>{props.details}</ContentDetalis>
        </ContentBox>
      </ServiceBoxContent>
    </>
  );
};

export default ServiceBox;
