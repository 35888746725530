import React from 'react';
import { Container } from 'styled-bootstrap-grid';
import { SectionWrapper } from '../common/SectionWrapper';
import { Grid, Cell } from '../../theme';
import ContentWrapper from '../common/ContentWrapper';
import { colors } from '../../theme/constants';
import ProjectBox from '../common/ProjectBox';
import SquareDesign from '../common/SquareDesign';

const ProjectsSection = (props) => {
  return (
    <>
      <SectionWrapper
        bgColor={colors.gray.mainLightGray}
        // bgUrl={'/images/bg-art-1.png'}
      >
        <SquareDesign></SquareDesign>
        <Container>
          <ContentWrapper
            center
            fontColor={colors.yellow.mainDarkYellow}
            colorFont={colors.blue.mainBlue}
            pageLabel={'// LATEST PROJECTS'}
            title={'Our Customers'}
          />
          <Grid rowGap={'1rem'} columnGap={'1rem'} rows={2} columns={2}>
            <Cell>
              <ProjectBox
                bgUrl={'/images/construction.jpg'}
                header={'SOFTiWARE'}
                details={
                  'We’re committed to building sustainable and high-quality solutions.'
                }
                iconName="nosted"
                bigIcon={true}
              ></ProjectBox>
            </Cell>
            <Cell>
              <ProjectBox
                bgUrl={'/images/transport.jpg'}
                header={'SOFTiWARE'}
                details={
                  'We help businesses elevate their value through custom software development.'
                }
                iconName="trygg"
              ></ProjectBox>
            </Cell>
            <Cell>
              <ProjectBox
                bgUrl={'/images/maaritime.jpg'}
                header={'SOFTiWARE'}
                details={
                  'We consulte and deliver top-level web and mobile applications.'
                }
                iconName="fram"
              ></ProjectBox>
            </Cell>
            <Cell>
              <ProjectBox
                bgUrl={'/images/forklift.jpg'}
                header={'SOFTiWARE'}
                details={
                  'We Deliver Solution with the Goal of Trusting Relationships.'
                }
                iconName="igland"
              ></ProjectBox>
            </Cell>
          </Grid>
        </Container>
      </SectionWrapper>
    </>
  );
};

export default ProjectsSection;
