import styled from 'styled-components';
import { device } from '../../theme';

const ElementorMargin = styled.div`
  margin-bottom: 4rem;
  @media ${device.laptop} {
    margin-top: -190px;
    margin-bottom: 8rem;
  }
`;

export { ElementorMargin };
