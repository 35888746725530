import React from 'react';
import { Container } from 'styled-bootstrap-grid';
import { SectionWrapper } from '../common/SectionWrapper';
import { Grid, Cell } from '../../theme';
import styled from 'styled-components';
import ContentWrapper from '../common/ContentWrapper';
import { colors } from '../../theme/constants';
import { ElementorMargin } from '../styles/ElementorMargin';
import ServiceBox from '../common/ServiceBox';
import FocusBox from '../common/FocusBox';
import { device } from '../../theme';

const ResponsiveWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media ${device.laptop} {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

const OurServicesSection = (props) => {
  return (
    <>
      <SectionWrapper>
        <Container>
          <ElementorMargin>
            <ResponsiveWrapper>
              <FocusBox
                bgUrl={'/images/bg-focus-1.jpg'}
                topic={'Core Value'}
                header={'Client Success'}
                detailsColor={colors.white}
                details={
                  'At Softiware, we take time to get to know our clients, their company, and how we can turn their problem into a solution. We are acutely adept at bridging the gap between business and technology.'
                }
              />
              <FocusBox
                bgUrl={'/images/bg-focus-2.jpg'}
                topic={'Core Mission'}
                header={'Results-Driven'}
                detailsColor={colors.white}
                details={
                  'We create an actionable strategy with specific deliverables to ensure our client success. It’s imperative to work with a software consultant who truly delivers measurable results. And that’s what we shine at.'
                }
              />
            </ResponsiveWrapper>
          </ElementorMargin>
          <ContentWrapper
            center
            fontColor={colors.yellow.mainDarkYellow}
            colorFont={colors.blue.mainBlue}
            pageLabel={'// OUR SERVICES'}
            title={'We Offer a Wide Variety of IT Services'}
          />
          <Grid rowGap={'1rem'} columnGap={'1rem'} rows={2} columns={3}>
            <Cell>
              <ServiceBox
                bgUrl={'/images/bg-iservice-box1.jpg'}
                header={'Web Development'}
                details={
                  'We are expert at developing web application using latest technology'
                }
                iconName="laptop-code"
              ></ServiceBox>
            </Cell>
            <Cell>
              <ServiceBox
                bgUrl={'/images/bg-iservice-box2.jpg'}
                header={'Mobile Development'}
                details={
                  'Mobile apps are crutial to business success, we cover you!'
                }
                iconName="mobile-solid"
              ></ServiceBox>
            </Cell>
            <Cell>
              <ServiceBox
                bgUrl={'/images/bg-iservice-box3.jpg'}
                header={'IT Consulting'}
                details={
                  'Our tech expert can help you to make your final decision with condifent!'
                }
                iconName="hands-helping-solid"
              ></ServiceBox>
            </Cell>
            <Cell>
              <ServiceBox
                bgUrl={'/images/bg-iservice-box4.jpg'}
                header={'IT Workshops'}
                details={
                  'We hold workshops for your employees or team to level up their skills!'
                }
                iconName="chalkboard-teacher-solid"
              ></ServiceBox>
            </Cell>
            <Cell>
              <ServiceBox
                bgUrl={'/images/bg-iservice-box5.jpg'}
                header={'Build Websites'}
                details={
                  'We build progressive web applications, new norm of website!'
                }
                iconName="file-code-solid"
              ></ServiceBox>
            </Cell>
            <Cell>
              <ServiceBox
                bgUrl={'/images/bg-iservice-box6.jpg'}
                header={'UI / UX Design'}
                details={'We design your idea before you start developing!'}
                iconName="crop-solid"
              ></ServiceBox>
            </Cell>
          </Grid>
        </Container>
      </SectionWrapper>
    </>
  );
};

export default OurServicesSection;
