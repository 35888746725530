import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { colors } from '../../theme/constants';
import { device } from '../../theme';

const BtnContainer = styled.div`
  display: flex;
  justify-content: ${(props) => (props.center ? 'center' : 'flex-start')};
  position: relative;
`;

const BtnWithIcon = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) =>
    props.backgoundcolor ? props.backgoundcolor : 'transparent'};
  cursor: pointer;
  width: ${(props) => (props.fullwidth ? '100%' : '')};
  color: #7141b1;
  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

const Btn = styled(Link)`
  display: flex;
  justify-content: center;
  background-image: none;
  border: 0.2rem solid transparent;
  font-weight: bold;
  font-size: 1.3rem;
  padding: 1.3rem 2rem;
  color: ${(props) => (props.color ? props.color : 'white')};
  background-color: ${(props) =>
    props.backgoundcolor ? props.backgoundcolor : 'transparent'};
  border-color: transparent;
  border-radius: 0.4rem;
  width: ${(props) => (props.fullwidth ? '100%' : '')};
  :hover {
    /* background-color: ${colors.blue.hoverBlue}; */
    background-color: transparent;
    border: 0.2rem solid #323232;
    color: black;
    /* background-image: linear-gradient(90deg,#00DEFF 0%,#7141B1 100%); */
    /* background-image: linear-gradient(90deg, rgba(50,50,93,1) 0%, rgba(66,71,112,1) 30%, rgba(245,190,88,1) 100%); */
  }

  @media ${device.tablet} {
  font-size: 1.5rem;
  line-height: 1.5;
  padding: 1.5rem 2.5rem;
  }
`;

const Button = (props) => {
  return (
    <BtnContainer center={props.center}>
      <BtnWithIcon
        fullwidth={props.fullWidth}
        backgoundcolor={props.bgColorIcon}
      >
        <Btn
          color={props.textColor}
          to={props.link ?? '/'}
          backgoundcolor={props.backgoundcolor}
          fullwidth={props.fullWidth}
        >
          {props.title}
        </Btn>
      </BtnWithIcon>
    </BtnContainer>
  );
};

export default Button;
