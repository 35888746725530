import React from 'react';
import { Container } from 'styled-bootstrap-grid';
import { SectionWrapper } from '../common/SectionWrapper';
import { Grid, Cell } from '../../theme';
import ContentWrapper from '../common/ContentWrapper';
import { colors } from '../../theme/constants';
import ServeBox from '../common/ServeBox';
import TriangleDesign from '../common/TriangleDesign';

const WhyChooseUsSection = (props) => {
  return (
    <>
      <SectionWrapper bgColor={colors.gray.mainLightGray}>
        <TriangleDesign></TriangleDesign>
        <Container>
          <ContentWrapper
            center
            fontColor={colors.yellow.mainDarkYellow}
            colorFont={colors.blue.mainBlue}
            pageLabel={'// WHY CHOOSE US'}
            title={'We Create Enterprise Business Applications'}
          />
          <Grid rowGap={'2rem'} columnGap={'2rem'} rows={2} columns={2}>
            <Cell>
              <ServeBox
                content={'01'}
                header={'Personal Touch'}
                details={
                  'We involve our clients at every stage of the development process.'
                }
                iconName="crown-solid"
              ></ServeBox>
            </Cell>
            <Cell>
              <ServeBox
                content={'02'}
                header={'Business Growth'}
                details={
                  'We help you discover new ways that technology can further enhance your growth.'
                }
                iconName="seeding-solid"
              ></ServeBox>
            </Cell>
            <Cell>
              <ServeBox
                content={'03'}
                header={'Top Quality'}
                details={
                  'Our team of designers, developers and engineers are elite industry professionals.'
                }
                iconName="award-solid"
              ></ServeBox>
            </Cell>
            <Cell>
              <ServeBox
                content={'04'}
                header={'Consulting Expertise'}
                details={'We consulte and deliver top-level applications.'}
                iconName="certificate-solid"
              ></ServeBox>
            </Cell>
          </Grid>
        </Container>
      </SectionWrapper>
    </>
  );
};

export default WhyChooseUsSection;
