import React from 'react';
import styled from 'styled-components';
import { colors } from '../../theme/constants';
import { device } from '../../theme';

const TextsContainer = styled.div`
  padding: 0 2rem;
  text-align: ${(props) => (props.center ? 'center' : '')};
  @media ${device.tablet} {
    padding: 0 11rem;
  }
`;

const PageLabel = styled.p`
  display: none;
  @media ${device.laptop} {
    font-size: 7.5rem;
    font-weight: 600;
    color: ${(props) => (props.fontColor ? props.fontColor : 'black')};
    position: relative;
    display: inline-block;
    margin-bottom: 0.2rem;
  }
`;

const ContentTitle = styled.h2`
  font-size: 2.6rem;
  font-weight: 400;
  color: ${(props) => (props.colorFont ? props.colorFont : '#1b1d21')};
  text-transform: none;
  padding-bottom: 3rem;
  line-height: 4.4rem;
  @media ${device.laptopM} {
    font-size: 3.6rem;
    font-weight: 800;
    line-height: 1.33;
  }
`;

const ContentDetails = styled.p`
  margin-bottom: 2rem;
  font-weight: 300;
  font-size: 2.1rem;
  line-height: 3.2rem;
  color: ${colors.blue.textBlue};
`;
const MainContentWrapper = (props) => {
  return (
    <>
      <TextsContainer center={props.center}>
        <PageLabel fontColor={props.fontColor}>{props.pageLabel}</PageLabel>
        <ContentTitle colorFont={props.colorFont}>{props.title}</ContentTitle>
        <ContentDetails>{props.contentDetails}</ContentDetails>
      </TextsContainer>
    </>
  );
};

export default MainContentWrapper;
