import React from 'react';
import styled from 'styled-components';
import { colors } from '../../theme/constants';
import { device } from '../../theme';
import { FetchSvg } from '../common/FetchSvg';

const BigNumber = styled.span`
  position: absolute;
  left: -1rem;
  top: 0;
  font-size: 7rem;
  font-weight: 800;
  line-height: 7.2rem;
  color: ${colors.gray.mainLightGray};
  transition: all 0.3s linear;
`;

const IconBgColor = styled.div`
  /* position: absolute;
  bottom: -6.5rem;
  right: -6.5rem;
  width: 15rem;
  height: 15rem;
  color: #fff; */
  background: ${colors.gray.mainGrey};
  border-radius: 50%;
`;

const ContentHeader = styled.h5`
  line-height: 3rem;
  color: ${colors.blue.mainBlue};
  margin-bottom: 3rem;
  transition: all 0.3s linear;
  font-size: 2rem;
  font-weight: 800;
  @media ${device.tablet} {
    margin-bottom: 2rem;
  }
`;

const ContentDetalis = styled.div`
  color: ${colors.blue.textBlue};
  font-size: 1.5rem;
`;

const ServeBoxContent = styled.div`
  overflow: hidden;
  position: relative;
  padding: 3rem 2rem;
  background: ${colors.gray.mainGrey};
  border: 0.2rem solid #ebebf1;
  color: #aeaacb;
  min-height: 1px;
  height: 20rem;
  transition: all 0.3s linear;
  :hover {
    background-color: white;
    ${BigNumber} {
      color: #f1f1f1;
    }
    ${ContentHeader} {
      color: ${colors.blue.mainBlue};
    }
    ${ContentDetalis} {
      color: ${colors.blue.textBlue};
    }
  }
`;

const IconShape = styled.span`
  color: ${colors.gray.mainGrey};
  position: absolute;
  top: 3.2rem;
  left: 3.5rem;
  svg,
  img {
    width: 0;
    height: 0;
  }
`;

const ContentBox = styled.div`
  position: relative;
  z-index: 1;
`;

const BgSvgIcon = (props) => {
  return (
    <IconShape>
      <FetchSvg name={props.iconName} />
    </IconShape>
  );
};

const ServeBox = (props) => {
  return (
    <>
      <ServeBoxContent>
        <BigNumber>{props.content}</BigNumber>
        <IconBgColor>
          <BgSvgIcon iconName={props.iconName} />
        </IconBgColor>
        <ContentBox>
          <ContentHeader>{props.header}</ContentHeader>
          <ContentDetalis>{props.details}</ContentDetalis>
          {/* <BtnExternal
            title={'learn more'}
            textColor={colors.yellow.mainDarkYellow}
            iconColor={colors.yellow.mainDarkYellow}
            smallerFont
          /> */}
        </ContentBox>
      </ServeBoxContent>
    </>
  );
};

export default ServeBox;
