import React from 'react';
import styled from 'styled-components';
import { colors } from '../../theme/constants';
import { device } from '../../theme';

const TriangleShape = styled.div`
  position: absolute;
  top: 0;
  left: 5%;
  transform: translate(100%, -15%) rotate(65deg);
`;

const TriangleContainer = styled.div`
  width: 10rem;
  height: 10rem;
  border-top-right-radius: 30%;
  position: relative;
  background-color: ${colors.yellow.mainLightYellow};
  opacity: 0.5;
  /* background-image: linear-gradient(45deg, #5533ff 0, #5533ff 100%); */
  text-align: left;
  transform: rotate(-60deg) skewX(-30deg) scale(1, 0.866);
  @media ${device.tablet} {
    width: 20rem;
    height: 20rem;
  }
  :before {
    transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707)
      translate(0, -50%);
    width: 10rem;
    height: 10rem;
    border-top-right-radius: 30%;
    content: '';
    position: absolute;
    background-color: inherit;
    /* background-image: inherit; */
    @media ${device.tablet} {
      width: 20rem;
      height: 20rem;
    }
  }
  :after {
    transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
    width: 10rem;
    height: 10rem;
    border-top-right-radius: 30%;
    content: '';
    position: absolute;
    background-color: inherit;
    /* background-image: inherit; */
    @media ${device.tablet} {
      width: 20rem;
      height: 20rem;
    }
  }
`;

const TriangleDesign = (props) => {
  return (
    <>
      <TriangleShape positionBottom={props.positionBottom}>
        <TriangleContainer></TriangleContainer>
      </TriangleShape>
    </>
  );
};

export default TriangleDesign;
